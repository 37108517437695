
import Vue from "vue";
import moment from "moment";

//class
import { DailyWorkReportKey } from "@/models/dailyWorkReport/DailyWorkReportKey";
import {
  Yorisen,
  YorisenText,
  YorisenTableHeaders,
  YorisenTable
} from "@/models/dailyWorkReport/Yorisen";
import { CodeKbnList } from "@/models/codeMaster/Code";
import {
  PulldownResponse,
  codeKbnFilter
} from "@/models/response/PulldownResponse";
import {
  CheckboxGroupItems,
  pulldownToCheckItems
} from "@/components/input/CheckboxGroup.vue";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

// Components
import { Headers } from "@/components/input/DailyWorkReportInput.vue";
import DailyWorkReportFormDialog from "@/components/dialog/DailyWorkReportFormDialog.vue";
import DataIterators from "@/components/table/dailyworkreport/dataiterators/DataIterators.vue";
import Snackbar from "@/components/dialog/Snackbar.vue";
import Overlay from "@/components/dialog/Overlay.vue";
import { ShainSearchRequest } from "@/models/ShainSearchRequest";
import Shain from "@/models/Shain";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";
import { OrderDetailsSearchResponse } from "@/models/orderDetails/response/OrderDetailsSearchResponse";
import { DATE_FORMAT } from "@/defines";
import SpecDocsDailog from "@/components/dialog/specdocs/SpecDocsDailog.vue";
import Store from "@/store/index";

const DATE = moment().format(DATE_FORMAT.DATE);
const YEAR_SUFFIX = moment().format(DATE_FORMAT.YEAR_SUFFIX);

export default Vue.extend({
  name: "Yorisen",
  mixins: [Common, ShowDialogs, Api],
  components: {
    DailyWorkReportFormDialog,
    DataIterators,
    Snackbar,
    Overlay,
    SpecDocsDailog
  },
  props: {
    date: {
      type: String,
      required: false,
      default: DATE
    },
    form: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      selectDate: this.date,
      isAdd: false,
      isOpen: false,
      maxRecord: 30,
      tableHeaders: YorisenTableHeaders,
      editRecord: new Yorisen(new DailyWorkReportKey({ workDate: DATE })),
      reportData: [] as Yorisen[], //テーブルデータ
      title: "撚線作業記録",
      tableName: "Yorisen",
      isLoading: false,
      codeGroup: [] as PulldownResponse[],
      userItems: [] as CheckboxGroupItems[], //作業者
      machineCodeItems: [] as CheckboxGroupItems[], //撚線機械名
      leftRightCheckItems: [] as CheckboxGroupItems[], //左・右
      conductorTypeCheckItems: [] as CheckboxGroupItems[], //導体種類
      insertTapeCheckItems: [] as CheckboxGroupItems[], //挿入テープ種類
      tapeRapCheckItems: [] as CheckboxGroupItems[], //テープラップ
      processTypeCheckItems: [] as CheckboxGroupItems[], //編組加工種類
      processPlaceCheckItems: [] as CheckboxGroupItems[], //編組加工先
      appearanceCheckItems: [] as CheckboxGroupItems[], //撚線外観
      emarkCheckItems: [] as CheckboxGroupItems[], //<PS>Eマーク
      isView: false,
      fileUrl: "",
      acceptNoSuffixSize: 5
    };
  },
  computed: {
    headers() {
      return [
        {
          ...YorisenText.workDate,
          type: "date-picker",
          disabled: true
        },
        {
          ...YorisenText.machineCode,
          type: "select",
          items: this.machineCodeItems
        },
        {
          ...YorisenText.userId,
          type: "select",
          items: this.userItems,
          required: true
        },
        { ...YorisenText.inspectionFlag, type: "checkbox" },
        {
          ...YorisenText.acceptNoPrefix,
          type: "text",
          maxLength: 2
        },
        {
          ...YorisenText.acceptNoSuffix,
          type: "text",
          maxLength: this.acceptNoSuffixSize,
          search: true
        },
        { ...YorisenText.successFlag, type: "checkbox" },
        { ...YorisenText.assemblyNo, type: "text", maxLength: 40 },
        { ...YorisenText.specificationNo, type: "text", maxLength: 40 },
        { ...YorisenText.workContents, type: "textarea", maxLength: 100 },
        {
          ...YorisenText.conductorType,
          type: "checkbox-group",
          items: this.conductorTypeCheckItems
        },
        { ...YorisenText.conductorStructure, type: "text", maxLength: 80 },
        { ...YorisenText.itemName, type: "text", maxLength: 80 },
        { ...YorisenText.size, type: "text", maxLength: 80 },
        { ...YorisenText.zenkeijaku, type: "number", digit: 10, scale: 2 },
        { ...YorisenText.yoriagari, type: "number", digit: 10, scale: 2 },
        { ...YorisenText.suryoMeisai, type: "text", maxLength: 80 },
        //{ ...YorisenText.drumNo, type: "text", maxLength: 80 },
        { ...YorisenText.drumType, type: "text", maxLength: 80 },
        { ...YorisenText.diceFirst, type: "number", digit: 10, scale: 2 },
        {
          ...YorisenText.yoriFirst,
          type: "checkbox-group",
          items: this.leftRightCheckItems
        },
        { ...YorisenText.pitchFirst, type: "number", digit: 10, scale: 2 },
        { ...YorisenText.diceFourth, type: "number", digit: 10, scale: 2 },
        {
          ...YorisenText.yoriFourth,
          type: "checkbox-group",
          items: this.leftRightCheckItems
        },
        { ...YorisenText.pitchFourth, type: "number", digit: 10, scale: 2 },
        { ...YorisenText.yoriOuter, type: "number", digit: 10, scale: 2 },
        { ...YorisenText.arrayConfirm, type: "checkbox" },
        { ...YorisenText.tubeType, type: "text", maxLength: 80 },
        { ...YorisenText.leadWire1, type: "text", maxLength: 80 },
        { ...YorisenText.leadWire2, type: "text", maxLength: 80 },
        { ...YorisenText.kaizaiFirst, type: "text", maxLength: 80 },
        { ...YorisenText.kaizaiFourth, type: "text", maxLength: 80 },
        { ...YorisenText.gear, type: "text", maxLength: 80 },
        {
          ...YorisenText.insertTape,
          type: "checkbox-group",
          items: this.insertTapeCheckItems
        },
        {
          ...YorisenText.eMark,
          type: "checkbox-group",
          items: this.emarkCheckItems
        },
        { ...YorisenText.tapeType, type: "text", maxLength: 80 },
        { ...YorisenText.tapeRange, type: "number", digit: 10, scale: 2 },
        {
          ...YorisenText.tapeRap,
          type: "checkbox-group",
          items: this.tapeRapCheckItems
        },
        {
          ...YorisenText.processType,
          type: "checkbox-group",
          items: this.processTypeCheckItems
        },
        {
          ...YorisenText.processPlace,
          type: "checkbox-group",
          items: this.processPlaceCheckItems
        },
        {
          ...YorisenText.appearance,
          type: "checkbox-group",
          items: this.appearanceCheckItems
        },
        { ...YorisenText.abnormality, type: "textarea", maxLength: 255 },
        { ...YorisenText.biko, type: "textarea", maxLength: 255 }
      ] as Headers[];
    },
    tableRecord() {
      const records: Yorisen[] = this.reportData;
      const result = records.map((y: Yorisen) => {
        const record = new YorisenTable(y);
        const user = this.userItems.find(e => e.value === y.userId);
        const machine = this.machineCodeItems.find(
          e => e.value === y.machineCode
        );
        const date = moment(y.updateTs).format(DATE_FORMAT.DATE_MINUTE);
        record.updateTs = date;
        record.userId = user ? user.text : null;
        record.machineCode = machine ? machine.text : null;
        return record;
      });
      return result;
    }
  },
  methods: {
    /**
     * 追加
     */
    onAdd() {
      console.log("add");
      this.isAdd = true;
      if (this.reportData.length >= this.maxRecord) {
        this.snackbarText = `${this.maxRecord}列以上は登録できません。`;
        this.snackbar = true;
        return;
      }
      const reocrdKey = new DailyWorkReportKey({ workDate: this.selectDate });
      this.editRecord = new Yorisen(reocrdKey);
      this.editRecord.acceptNoPrefix = YEAR_SUFFIX;
      this.isOpen = true;
    },
    /**
     * 編集
     * @param value index
     */
    onEdit(value: number) {
      console.log("edit");
      this.isAdd = false;
      this.editRecord = Object.assign({}, this.reportData[value]);
      this.editRecord.acceptNoPrefix =
        this.editRecord.acceptNo?.slice(0, -this.acceptNoSuffixSize) ?? null;
      this.editRecord.acceptNoSuffix =
        this.editRecord.acceptNo?.slice(-this.acceptNoSuffixSize) ?? null;
      this.isOpen = true;
    },
    /**
     * コピー
     * @param value DailyWorkReportSearchResponse
     */
    async onCopy(value: DailyWorkReportSearchResponse, close: () => void) {
      console.log("copy", value);
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        const param = new DailyWorkReportKey(value);
        const result = await api.$post<Yorisen>(
          api.Paths.dailyWorkReport.yorisen.searchKey,
          param
        );
        result.workDate = this.selectDate;
        this.editRecord = result;
        this.editRecord.acceptNoPrefix =
          this.editRecord.acceptNo?.slice(0, -this.acceptNoSuffixSize) ?? null;
        this.editRecord.acceptNoSuffix =
          this.editRecord.acceptNo?.slice(-this.acceptNoSuffixSize) ?? null;
        close();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("copy end");
        this.isLoading = false;
      }
    },
    async onFind(value: OrderDetailsSearchResponse, close: () => void) {
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        if (value.alreadyAssigned === null) {
          showDialogs.$error("受注番号の登録がありません。");
        } else {
          if (value.alreadyAssigned) {
            showDialogs.$error("既に他の作業日報データで引用されています。");
            value.juhno = null;
            value.syno = null;
            value.shonm = null;
            value.kikaku = null;
            value.denno = null;
          }
        }
        this.editRecord.acceptNo = value.juhno;
        this.editRecord.acceptNoSuffix = value.juhno
          ? value.juhno.slice(-this.acceptNoSuffixSize)
          : null;
        this.editRecord.specificationNo = value.syno;
        this.editRecord.itemName = value.shonm;
        this.editRecord.size = value.kikaku;
        this.editRecord.denno = value.denno;
        this.editRecord.gyono = value.gyono;
        close();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        this.isLoading = false;
      }
    },
    // ==============================
    // 検索
    // ==============================
    async onSearch() {
      console.log("onSearch start");
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        this.reportData = await this.getYorisenRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSearch end");
        this.isLoading = false;
      }
    },
    // ==============================
    // 保存
    // ==============================
    async onSave(saveValue: Yorisen) {
      console.log("onSave start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      this.isLoading = true;
      try {
        if (this.isAdd) {
          await api.$post(api.Paths.dailyWorkReport.yorisen.insert, saveValue);
        } else {
          await api.$post(api.Paths.dailyWorkReport.yorisen.update, saveValue);
        }
        showDialogs.$info("更新しました。", "更新");
        this.reportData = await this.getYorisenRecord();
        this.isOpen = false;
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onSave end");
        this.isLoading = false;
      }
    },
    /**
     * 削除
     * @param index
     */
    async onDelete(index: number) {
      console.log("onDelete start", index);
      const api = new Api();
      const showDialogs = new ShowDialogs();
      const title = "確認";
      const message = `${index +
        1}列目の作業記録を削除します。\nよろしいですか？`;
      const confirm = await showDialogs.$deleteConfirm(message, title);
      if (!confirm) return;

      this.isLoading = true;
      try {
        const target = this.reportData[index];
        await api.$post(api.Paths.dailyWorkReport.yorisen.delete, target);
        showDialogs.$info("削除しました。", "情報");
        this.reportData = await this.getYorisenRecord();
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("onDelete end");
        this.isLoading = false;
      }
    },
    async onView(index: number) {
      const api = new Api();
      this.fileUrl = `${process.env.VUE_APP_API_BASE_URL}${api.Paths.specDocs.searchkey}?token=${Store.state.mtoken}&denno=${this.reportData[index].denno}&gyono=${this.reportData[index].gyono}`;
      this.isView = true;
    },
    /**
     * 撚線作業記録データ取得
     */
    async getYorisenRecord() {
      const api = new Api();
      const param = { workDate: new Date(this.selectDate) };
      const result = await api.$post<Yorisen[]>(
        api.Paths.dailyWorkReport.yorisen.search,
        param
      );
      return result;
    },
    /**
     * データベースから選択項目データ取得
     */
    async getItems() {
      console.log("getItems start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      try {
        //apiパス
        const userPath = api.Paths.shainSearch;
        const codeMasterPath = api.Paths.codeMasterPulldown;
        const codeGroupPath = api.Paths.codeGroupPulldown;

        //リクエスト
        const shainRequest = new ShainSearchRequest({
          department: [this.$store.state.dailyWorkReportStore.nippoType],
          isValid: "1"
        });
        const userPromise = api.$post<Shain[]>(userPath, shainRequest);
        const codeMasterPromise = api.$get<PulldownResponse[]>(codeMasterPath);
        const codeGroupPromise = api.$get<PulldownResponse[]>(codeGroupPath);
        const [user, codeMaster, codeGroup] = await Promise.all([
          userPromise,
          codeMasterPromise,
          codeGroupPromise
        ]);

        this.codeGroup = codeGroup;
        //ユーザデータ取得
        this.userItems = user.map(e => ({ value: e.userId, text: e.userName }));
        //コードマスタのデータをすべて取得
        //撚線機械名
        const yorisenMachine = codeKbnFilter(codeMaster, CodeKbnList.YORISN);
        this.machineCodeItems = pulldownToCheckItems(yorisenMachine);
        //左・右
        const leftRight = codeKbnFilter(codeMaster, CodeKbnList.LFTRGT);
        this.leftRightCheckItems = pulldownToCheckItems(leftRight);
        //導体種類
        const conductorType = codeKbnFilter(codeMaster, CodeKbnList.CONDCT);
        this.conductorTypeCheckItems = pulldownToCheckItems(conductorType);
        //挿入テープ種類
        const insertTape = codeKbnFilter(codeMaster, CodeKbnList.INTAPE);
        this.insertTapeCheckItems = pulldownToCheckItems(insertTape);
        //テープラップ
        const tapeRap = codeKbnFilter(codeMaster, CodeKbnList.TPRAP);
        this.tapeRapCheckItems = pulldownToCheckItems(tapeRap);
        //編組加工種類
        const processType = codeKbnFilter(codeMaster, CodeKbnList.COMPTP);
        this.processTypeCheckItems = pulldownToCheckItems(processType);
        //編組加工先
        const processPlace = codeKbnFilter(codeMaster, CodeKbnList.COMPSK);
        this.processPlaceCheckItems = pulldownToCheckItems(processPlace);
        //撚線外観
        const appearanceCheck = codeKbnFilter(codeMaster, CodeKbnList.GAIKAN);
        this.appearanceCheckItems = pulldownToCheckItems(appearanceCheck);
        //<PS>Eマーク
        const emarkCheck = codeKbnFilter(codeMaster, CodeKbnList.YESNO);
        this.emarkCheckItems = pulldownToCheckItems(emarkCheck);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
      }
    },
    /**
     * テーブルコード取得
     */
    async getCode() {
      console.log("getCode start");
      const showDialogs = new ShowDialogs();
      const api = new Api();

      try {
        //apiパス
        const path = api.Paths.dailyWorkReport.yorisen.code;

        //リクエスト
        const code = await api.$get<string>(path);

        this.$store.commit("dailyWorkReportStore/nippoTypeSet", code);
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("getItems end");
      }
    }
  },
  async created() {
    await this.getCode();
    await this.getItems();
    await this.onSearch();
  }
});
